<template>
  <div class="outer-page">
    <x-table
      :no-data-text="CA('farmers_check') ? '暂无数据' : '暂无数据查看权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @search="search"
      @page-change="pageChange"
      @loadExpend="loadExpend"
    ></x-table>
    <Modal
      v-model="modal.show"
      :title="modal.title"
      :width="900"
      @on-visible-change="modalChange"
    >
      <Form ref="form" :model="form" :label-width="140" :rules="rules">
        <Row>
          <Col span="11">
            <FormItem label="企业名称" prop="farmerName">
              <Input
                clearable
                placeholder="请输入企业名称"
                v-model="form.farmerName"
              ></Input>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem label="统一社会信用代码" prop="creditCode">
              <Input
                clearable
                placeholder="请输入统一社会信用代码"
                v-model="form.creditCode"
              ></Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem label="企业类型" prop="enterpriseType">
              <Select
                clearable
                placeholder="请输入企业类型"
                v-model="form.enterpriseType"
              >
                <Option
                  v-for="(item, index) in companyData"
                  :key="index"
                  :value="item.id"
                  >{{ item.name }}</Option
                >
              </Select>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem label="种养一体">
              <Select v-model="form.isFarmPlant">
                <Option :value="item.value" v-for="item in cityList" :key="item.label">{{item.label}}</Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11" >
            <FormItem label="养殖场数量" prop='farmCount'>
              <Input placeholder="请输入养殖数量" v-model="form.farmCount"></Input>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem label="养殖品种" prop="typeInfo">
              <Select
                v-model="form.typeInfo"
                multiple
                placeholder="请选择养殖品种"
              >
                <Option
                  v-for="(item, index) in catData"
                  :key="index"
                  :value="item.id + '/' + item.name"
                  >{{ item.name }}</Option
                >
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem label="联系人" prop="name">
              <Input
                clearable
                placeholder="请输入联系人名称"
                v-model="form.name"
              ></Input> </FormItem
          ></Col>
          <Col span="11" offset="1">
            <FormItem label="电话号码" prop="tel">
              <Input
                clearable
                placeholder="请输入电话号码"
                v-model="form.tel"
              ></Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11"> </Col>
          <Col span="11" offset="1">
            <!-- <FormItem label="营业期限" prop="businessTerm">
              <Input
                clearable
                placeholder="请输入营业期限"
                v-model="form.businessTerm"
              ></Input> 
              </FormItem
          > -->
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem label="省市区编码" prop="region">
              <Cascader
                :data="areaList"
                v-model="form.region"
                :load-data="loadData"
                change-on-select
                placeholder="请选择区域"
              ></Cascader>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem label="养殖地址" prop="farmerAddress">
              <Input placeholder="请输入养殖地址" v-model="form.farmerAddress">
                <span
                  slot="append"
                  class="iconfont"
                  style="cursor: pointer"
                  @click="mapModelShow"
                  >&#xe648;</span
                >
              </Input>
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col span="23">
            <FormItem label="规模信息">
              <Input type="textarea" :rows="4"></Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem label="图片上传">
              <upload-image
                :max="1"
                :clear-flag="!modal.show"
                v-model="form.logo"
              ></upload-image>
            </FormItem>
          </Col>
        </Row>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="submit">提交</Button>
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
    <Modal v-model="mapModal" fullscreen>
      <search-map
        v-if="mapModal"
        :currentData="currentData"
        @back-location="dealMapData"
      ></search-map>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="() => (mapModal = false)">提交</Button>
        <Button @click="() => (mapModal = false)">取消</Button>
      </p>
    </Modal>
    <farmers-detail v-model="farmersDetail.show" :farmersDetailInfo='farmersDetail.info' :farmListInfo="farmListInfo" :servicePathInfo="servicePathInfo"></farmers-detail>
    <picture-view v-model="picture.show" :src="picture.src"></picture-view>
  </div>
</template>

<script>
import { mapState } from "vuex";
import searchMap from "@/components/searchMap";
import pictureView from "@/components/picture_view"
import CU from "@/common/util"
import farmersDetail from './farmersDetail.vue'
export default {
  name: "",
  components: {
    searchMap,
    pictureView,
    farmersDetail,
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: "LOGO",
            width: 100,
            align: "center",
            render: (h, { row }) => {
              if (!row.logo) return <span>--</span>;
              return (
                <div style="width:60px;height:60px;padding:4px">
                  <img
                    style="width:100%;height:100%"
                    src={this.getImgUrl(row.servicePath, row.logo)}
                    onClick={() => this.lookPicture(row.servicePath + row.logo)}
                  />
                </div>
              );
            },
          },
          {
            title: "企业名称",
            key: "farmerName",
            minWidth: 100,
          },
          {
            title: "企业类型",
            width: 100,
            align: "center",
            key: "enterpriseTypeName",
          },
          {
            title: "统一社会信用代码",
            width: 150,
            key: "creditCode",
          },
          // {
          //   title: "所属行业",
          //   width: 140,
          //   align: "center",
          //   key: "industry",
          // },
          {
            title: "养殖场(个)",
            align: "center",
            width: 110,
            render: (h, { row }) => {
              return <span>{row.farmCount} 个</span>;
            },
          },
          {
            title: "预计年产量",
            width: 110,
            key: "ceilingCount",
          },
          {
            title: "养殖品种",
            align: "center",
            width: 100,
            key: "typeName",
          },
          {
            title: "联系人",
            key: "name",
            width: 110,
          },
          {
            title: "电话",
            width: 130,
            key: "tel",
          },
          {
            title: "区域",
            tooltip: true,
            key: "regionCodeName",
          },
          {
            title: "地址",
            minWidth: 100,
            key: "farmerAddress",
          },
          // {
          //   title: "创建时间",
          //   width: 180,
          //   key: "createTime",
          // },
          {
            title: "操作",
            width: 150,
            render: (h, { row }) => {
              return (
                <div>
                  {
                //       this.CA("farmers_edit") && (
                //     <a
                //       style="margin-right:10px"
                //       on-click={() => this.edit(row)}
                //     >
                //       编辑
                //     </a>
                //   )
                  }
                  {
                //       this.CA("farmers_delete") && (
                //     <Poptip
                //       confirm
                //       transfer
                //       title="确定删除吗?"
                //       on-on-ok={() => this.delete(row.id)}
                //     >
                //       <a>删除</a>
                //     </Poptip>
                //   )
                  }
                  {this.CA("farmers_view") && (
                    <a
                      style="margin-left: 10px"
                      onClick={() => this.checkInfo(row)}
                    >
                      详情
                    </a>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      search_data: {},
      modal: {
        title: "",
        show: false,
        submitLoading: false,
      },
      form: {
        id: "",
        name: "",
        tel: "",
        farmerAddress: "",
        type: [],
        typeName: [],
        typeInfo: [],
        category: "",
        farmerName: "",
        farmCount:'',
        isFarmPlant:'',
        thirdLongLat: "",
        //社会统一信用代码
        creditCode: "",
        //企业类型
        enterpriseType: "",
        //营业期限
        businessTerm: "",
        //所属行业
        industry: "养殖业",
        //省市区编码
        region: [],
        //省市区编码字符串
        regionCode: "",
        //经营范围
        businessScope: "",
        logo: "",
        //预计年产量
        // ceilingCount:''
        options:'',
      },

      //省市区列表
      areaList: [],
      rules: {
        name: [{ required: true, message: "请填写联系人" }],
        farmerName: [{ required: true, message: "请填写企业名称" }],
        tel: [
          { required: true, message: "请填写联系电话" },
          {
            validator(rule, value, callback) {
              if (!/^1[3|4|5|7|8|9][0-9]{9}$/.test(value)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "手机号格式不正确",
            trigger: "change",
          },
        ],
        farmerAddress: [{ required: true, message: "请填写养殖地址" }],
        typeInfo: [{ required: true, message: "请选择养殖品种" }],
        creditCode: [{ required: true, message: "请输入社会统一信用代码" }],
        enterpriseType: [{ required: true, message: "请选择企业类型" }],
        // businessTerm: [{ required: true, message: "请输入营业期限" }],
        // industry: [{ required: true, message: "请输入所属行业" }],
        region: [{ required: true, message: "请输入省市区编码" }],
        // businessScope: [{ required: true, message: "请输入经营范围" }],
        farmCount:[{ required:true , message:"请输入养殖场数量" }],
      },
      catData: [],

      selAddressShow: false,
      currentData: null,
      mapModal: false,
      companyData: [],
      

      picture: {
        show: false,
        src: "",
      },
      farmersDetail:{
        show:false,
        info:{}
      },
      cityList:[
        {value:'1',label:'是'},
        {value:'2',label:'否'}
      ],
      farmListInfo:[],
      servicePathInfo:''
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
    config() {
      let config = {
        filter: {
          loadExpend: {
            loadExpendName: "导出",
            ca: "farmers_export",
          },
          width: 200,
          filterBox: [
            {
              conditionName: "企业名称",
              component: "input",
              field: "farmerName",
              defaultValue: "",
            },
            {
              conditionName: "企业类型",
              component: "select",
              field: "enterpriseType",
              defaultValue: "",
              data: this.companyData,
              parameterField: "id",
              showField: "name",
            },
            {
              conditionName: "养殖区域",
              component: "cascader",
              field: "regionCode",
              defaultValue: [],
              data: this.areaList,
              isLoadData: true,
              loadData: this.loadData,
            },
          ],
        },
        page: this.page,
      };
      return config;
    },
  },
  methods: {
    //导入
    loadEnter() {
      let input = document.createElement("input");
      input.type = "file";
      input.onchange = (e) => {
        if (!input.files || input.files.length === 0) return;
        //通过FormData构造函数创建一个空对象
        let formdata = new FormData();
        //通过append()方法来追加数据
        formdata.append("file", input.files[0]);
        formdata.append("userId", localStorage.getItem("userId"));
        formdata.append("companyNo", localStorage.getItem("companyNo"));
        formdata.append("category", this.user.category + 1);
        this.$post(this.$api.FAMRMING.UPLOAD, formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then((res) => {
          this.getList();
        });
      };
      input.click();
    },
    dealMapData(mapData) {
      this.form.farmerAddress = mapData.address;
      this.form.thirdLongLat = mapData.thirdLongLat;
    },
    mapModelShow() {
      this.currentData = this.form.thirdLongLat;
      this.mapModal = true;
    },
    getList() {
      if (!this.CA("farmers_check")) return;
      this.table.loading = true;
      this.$post(this.$api.FAMRMING.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    getCategory() {
      this.$post(this.$api.PRODUCT_CATEGORY.LIST, {
        type: "2",
      }).then((res) => {
        this.catData = res.list;
      });
    },
    //企业类型
    getCompanyCategory() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: "203",
      }).then((res) => {
        this.companyData = res.list;
      });
    },
    search(value) {
      let n;
      for (let i = 0; i < value.regionCode.length; i++) {
        n = value.regionCode[value.regionCode.length - 1];
      }
      let obj = {
        ...value,
        regionCode: n,
      };
      this.search_data = obj;
      this.getList();
    },
    add() {
      this.modal = {
        show: true,
        title: "新增养殖户",
        submitLoading: false,
      };
    },
    edit(row) {
      
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.form.typeInfo = [];
      this.form.type = row.type.split(",");
      this.form.typeName = row.typeName.split(",");
      this.form.region = row.regionCode.split(",");
      this.form.logo = this.getImgUrl(row.servicePath, row.logo);
      this.form.farmCount = row.farmCount.toString()
      for (let i = 0; i < row.type.length; i++) {
        let str = row.type[i] + "/" + row.typeName[i];
        this.form.typeInfo.push(str);
      }
      this.modal = {
        show: true,
        title: "编辑养殖户",
        submitLoading: false,
      };
    },
    delete(id) {
      this.$post(this.$api.FAMRMING.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功！");
        this.getList();
      });
    },
    //省市区列表接口
    getAreaList() {
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: "500153",
      }).then((res) => {
        res.forEach((item) => {
          item.label = item.name;
          item.value = item.id;
          item.loading = false;
          item.children = [{label:'无',value:'',disabled:"ture"}];
        });
        this.areaList = res;
      });
    },
    loadData(item, callback) {
      item.loading = true;
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: item.id,
      })
        .then((res) => {
          res.forEach((item) => {
            item.label = item.name;
            item.value = item.id;
            if (item.hasChild == "true") {
              item.loading = false;
              item.children = [];
            }
          });
          item.children = res;
          callback();
        })
        .finally(() => {
          item.loading = false;
        });
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = { ...this.form };
        let url;
        if (params.id) {
          url = this.$api.FAMRMING.UPDATE;
        } else {
          url = this.$api.FAMRMING.ADD;
        }
        params.type = [];
        params.typeName = [];
        params.category = +this.user.category + 1;
        this.form.typeInfo.forEach((item) => {
          params.type.push(item.split("/")[0]);
          params.typeName.push(item.split("/")[1]);
        });
        params.type = params.type.toString();
        params.farmCount = params.farmCount.toString();
        params.regionCode = this.form.region.join(",");
        delete params.region;
        params.typeName = params.typeName.toString();
        delete params.typeInfo;
        let index = this.form.logo ? this.form.logo.indexOf("/", 10) : -1;
        if (index !== -1) {
          params.logo = this.form.logo.substring(index + 1);
        }
        this.modal.submitLoading = true;
        this.$post(url, params)
          .then(() => {
            this.$Message.success(params.id ? "修改成功！" : "增加成功！");
            this.getList();
            this.modal.show = false;
          })
          .finally(() => {
            this.modal.submitLoading = false;
          });
      });
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    modalChange(visible) {
      if (visible) return;
      this.form = {
        id: "",
        name: "",
        tel: "",
        farmerAddress: "",
        type: [],
        typeName: [],
        typeInfo: [],
        farmerName: "",
        thirdLongLat: "",
        creditCode: "",
        enterpriseType: "",
        businessTerm: "",
        industry: "养殖业",
        regionCode: "",
        businessScope: "",
        region: [],
        logo: "",
        farmCount:''
      };
      this.$refs.form.resetFields();
    },

    lookPicture(url) {
      this.picture = {
        show: true,
        src: url
      }
    },

    loadExpend() {
      let columns = this.table.columns.map(item => item.title)
      columns.pop()
      let data = this.table.data.map(row => {
        let rowData = this.table.columns.map(column => {
          if (column.title == 'LOGO') {
            return this.getImgUrl(row.servicePath, row.logo)
          } else if (column.title == '养殖场(个)') {
            return row.farmCount+'个'
          }
          return row[column.key]
        })
        return rowData
      })
      CU.exportExcel(columns, data, '养殖户企业')
    },
    //详情
    checkInfo(row){
      this.$post(this.$api.FAMRMING.INFO,{
          companyNo:row.companyNo,
          userId:row.userId
      }).then((res)=>{
        this.farmListInfo=res.farmList
        this.servicePathInfo=res.servicePath
        this.farmersDetail = {
        show: true,
        info: row,
      };
      })


      
    }
  },
  mounted() {
    this.getList();
    this.getCategory();
    this.getCompanyCategory();
    this.getAreaList();
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
}
</style>